import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import loadable from "@loadable/component";
import RedirigirNuevo from "../usuarios/RedirigirNuevo";
// import RedirigirAltaComercio from "../usuarios/RedirigirAltaComercio";
// import EncargadoRegister from "../usuarios/RegistrarEncargado/EncargadoRegister";

const PublicRoute = loadable(() => import("../components/PublicRoute"));
const PublicRoutePro = loadable(() => import("../components/PublicRoutePro"));

// const Login = loadable(() => import("../usuarios/LoginPage"));
const ListPublic = loadable(() => import("../listPublic"));

// const NewCompany = loadable(() => import("../empresas/NewCompany"));
// const Encript = loadable(() => import("../Encript/Encript"));
const SupplierRegister = loadable(() => import("../usuarios/supplierRegister"));
const Blog = loadable(() => import("../Blog/Blog"));
const Blogs_1 = loadable(() => import("../Blog/Blogs_1"));
const Blogs_2 = loadable(() => import("../Blog/Blogs_2"));
const Blogs_3 = loadable(() => import("../Blog/Blogs_3"));
const Blogs_4 = loadable(() => import("../Blog/Blogs_4"));
const Blogs_5 = loadable(() => import("../Blog/Blogs_5"));
const Blogs_6 = loadable(() => import("../Blog/Blogs_6"));
const Blogs_7 = loadable(() => import("../Blog/Blogs_7"));
const Privacidad = loadable(() => import("../Privacidad/Privacidad"));
const ClientRegister = loadable(() => import("../usuarios/RegistrarComercio/clientRegister"));
const EncargadoRegister  = loadable(() => import( "../usuarios/RegistrarEncargado/EncargadoRegister"));

const Home = loadable(() => import("../Home/Home"));
// const HomeProveedores = loadable(() => import("../HomeProveedor/Home"));
const HomeProveedores2 = loadable(() => import("../HomeProveedor2/Home"));
const History = loadable(() => import("../History/Home"));

function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/" component={HomeProveedores2} />
        <PublicRoutePro exact path="/locales" component={Home}/>
        {/* <PublicRoutePro exact path="/proveedores2" component={HomeProveedores2}/> */}
        <Route exact path="/privacidad/" component={Privacidad} />
        <Redirect exact from="/alta_negocio" to="/alta_negocio/marketplace" />
        {/* <Route exact path="/alta_negocio" component={RedirigirAltaComercio} /> */}
        <Route exact path="/alta_negocio/:key_datos" component={ClientRegister} />
        <Route exact path="/alta_negocio/:key_datos/not_route" component={ClientRegister} />
        <Route exact path="/alta_negocio/encargado/:proveedor_id/:local_id" component={EncargadoRegister} />
        <PublicRoute exact path="/blog/" component={Blog} />
        <PublicRoute
          exact
          path="/blog/la_carrera_de_diezmil_mts"
          component={Blogs_5}
        />
        <PublicRoute
          exact
          path="/blog/negocios_vs_ecommerce"
          component={Blogs_3}
        />
        <PublicRoute
          exact
          path="/blog/2020/04/27/negocios-fisicos-vs-ecommerce"
          component={Blogs_3}
        />
        <PublicRoute
          exact
          path="/blog/el_pescador_y_la_tormenta"
          component={Blogs_2}
        />
        <PublicRoute
          exact
          path="/blog/2020/04/13/el-pescador-y-la-tormenta"
          component={Blogs_2}
        />
        <PublicRoute
          exact
          path="/blog/despídan_a_ese_vendedor"
          component={Blogs_1}
        />
        <PublicRoute
          exact
          path="/blog/atención_al_cliente.espada_de_doble_filo"
          component={Blogs_4}
        />
        <PublicRoute
          exact
          path="/blog/el_superpoder_secreto"
          component={Blogs_6}
        />
        <PublicRoute
          exact
          path="/blog/esa_rara_especie-los_emprendedores"
          component={Blogs_7}
        />

        {/* <Route exact path="/admin/wisconsin/company" component={NewCompany} /> */}
        {/* <Route exact path="/admin/wisconsin/encript" component={Encript} /> */}
        <Route exact path="/supplier" component={RedirigirNuevo} />
        <Route exact path="/supplier/pedidos" component={RedirigirNuevo} />
        <Route exact path="/supplier/login" component={RedirigirNuevo} />
        <Redirect exact from="/supplier/signup/" to="/" />
        <Route exact path="/supplier/signup/:id" component={SupplierRegister} />

        {/* routes publics */}
        <Redirect exact from="/list-public" to="/" />
        <Route path="/list-public/:link_id" component={ListPublic} />

        {/* routes landings personalizadas proveedores */}
        <Route exact path="/perfil/:proveedor" component={History} />




        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
