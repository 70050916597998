import { GET_LISTA, ERROR_LISTA, CHANGE_CATEGORIA } from "../constants/types";

const initialState = {
  error: null,
  productos: null,
  productos_sort: null,
  categoria: null,
};

export const listViewReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_LISTA:
      return {
        ...state,
        error: false,
        marcas: actions.payload.lista.marcas,
        categorias: actions.payload.lista.categorias,
        tipo_sort: actions.payload.lista.tipo_sort,
        nombre: actions.payload.lista.nombre,
        nombreEmpresa: actions.payload.lista.nombreEmpresa,
        logo_color: actions.payload.lista.logo_color,
        banner_mobile: actions.payload.lista.banner_mobile,
        banner_desktop: actions.payload.lista.banner_desktop,
        paginaweb: actions.payload.lista.paginaweb,
        facebook: actions.payload.lista.facebook,
        instagram: actions.payload.lista.instagram,
        vigencia: actions.payload.lista.vigencia,
        productos: sortProductos(
          actions.payload.lista.tipo_sort,
          actions.payload.lista.marcas,
          actions.payload.lista.categorias,
          actions.payload.lista.productos
        ),
      };
    case ERROR_LISTA:
      return {
        ...state,
        error: true,
      };
    case CHANGE_CATEGORIA:
      return {
        ...state,
        categoria: actions.payload.categoria,
      };

    default:
      return state;
  }
};

const sortProductos = (tipo_sort, marcas, categorias, productos_store) => {
  let filtrador = [];
  if (tipo_sort === 1) filtrador = categorias;
  else filtrador = marcas;

  let productosTotal = [];
  // eslint-disable-next-line
  filtrador.map((filtro) => {
    let productos = [];
    if (tipo_sort === 1)
      productos = productos_store.filter(
        (element) => element.categoria === filtro.DISTINCT
      );
    else
      productos = productos_store.filter(
        (element) => element.marca === filtro.DISTINCT
      );
    // eslint-disable-next-line

    if (productos.length) {
      let productosOrderNombre = productos.sort(function (a, b) {
        a = a.nombre.toUpperCase();
        b = b.nombre.toUpperCase();
        return a > b ? 1 : a < b ? -1 : 0;
      });
      let productosOrderCodinterno = productosOrderNombre.sort(function (a, b) {
        a = a.codigo === null ? "~" : a.codigo.toUpperCase();
        b = b.codigo === null ? "~" : b.codigo.toUpperCase();
        return a > b ? 1 : a < b ? -1 : 0;
      });
      productosTotal.push({
        filtro: filtro.DISTINCT,
        productos: productosOrderCodinterno,
      });
    }
  });
  let productosTotalFilter = productosTotal.sort(function (a, b) {
    a = a.filtro.toLowerCase();
    b = b.filtro.toLowerCase();
    return a > b ? 1 : a < b ? -1 : 0;
  });
  console.log("prod",productosTotalFilter);
  return productosTotalFilter;
};
