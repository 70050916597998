import React, { useEffect } from 'react'

const RedirigirNuevo = () => {
    useEffect(() => {
        window.location.href = "https://app.mayah.co";
    }, []);
    return (
        <div>
            
        </div>
    )
}

export default RedirigirNuevo
